import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// Styles
import s from "./Button.module.sass"

const Button = ({ className = "", children, type, onClick }) => (
  <button
    className={classNames(className, s.button, {
      [s.white]: className.includes("white"),
      [s.green]: className.includes("green"),
      [s.transparent]: className.includes("transparent"),
      [s.pulse]: className.includes("pulse")
    })}
    type={type}
    onClick={onClick}
  >
    {children}
  </button>
)

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  onClick: PropTypes.func,
}

Button.defaultProps = {
  className: '',
  type: `button`,
}

export default Button
