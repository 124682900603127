import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// Styles
import s from "./Container.module.sass"

const Container = ({ children, size, padding }) => (
  <div className={classNames(s.container, { 
    [s.fluid]: size === "fluid",
    [s.noPadding]: padding === "no-padding" 
  } )}>
    {children}
  </div>
)

Container.propTypes = {
  size: PropTypes.oneOf(["", "fluid"]),
  padding: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Container.defaultProps = {
  size: ``,
  padding: ``,
}

export default Container
