import React from "react"
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames"

// styles
import s from "./accordion.module.sass"

export default function Accordion({ i, expanded, setExpanded, title, text, theme, className = "" }) {
    const isOpen = i === expanded;

    return (
        <div className={className}>
            <motion.p
                initial={false}
                onClick={() => setExpanded(isOpen ? false : i)}
                className={classNames(
                    {
                        [s.dropdown]: theme.includes("dropdown"),
                        [s.open]: isOpen,
                    }
                )}
            >
                {title}
                <i className={s.icon}></i>
            </motion.p>
            <AnimatePresence initial={false}>
                {isOpen && (
                <motion.p
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                        open: { opacity: 1, height: "auto" },
                        collapsed: { opacity: 0, height: 0 }
                    }}
                    transition={{ duration: 0.3, type: "easeInOut" }}
                    className={classNames({[s.body]: theme.includes("dropdown")})}
                >
                    {text}
                </motion.p>
                )}
            </AnimatePresence>
        </div>
    );
};