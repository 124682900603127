import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// Styles
import s from "./Row.module.sass"

const Row = ({ children, align, justify, wrap, direction }) => {
  return (
    <div
      className={classNames(s.row, {
        [s.alignCenter]: align.includes("align-items-center"),
        [s.alignStart]: align.includes("align-items-start"),
        [s.alignEnd]: align.includes("align-items-end"),
        [s.justifyCenter]: justify.includes("justify-content-center"),
        [s.justifyEnd]: justify.includes("justify-content-end"),
        [s.justifyBetween]: justify.includes("justify-content-between"),
        [s.justifyAround]: justify.includes("justify-content-around"),
        [s.noWrap]: wrap.includes("nowrap"),
        [s.wrap]: wrap.includes("wrap"),
        [s.rowReverse]: direction.includes("row-reverse"),
      })}
    >
      {children}
    </div>
  )
}
Row.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(["", "align-items-center","align-items-start","align-items-end"]),
  justify: PropTypes.oneOf(["", "justify-content-center","justify-content-end","justify-content-between","justify-content-around"]),
  wrap: PropTypes.oneOf(["","wrap","nowrap"]),
  direction: PropTypes.oneOf(["","row","row-reverse"])
}

Row.defaultProps = {
  align: ``,
  justify: ``,
  wrap: '',
  direction: ``
}

export default Row