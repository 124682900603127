import React, { useRef, useState } from "react"
import InputMask from "react-input-mask";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames"
import Modal from 'react-modal'
// styles
import s from './form.module.sass'
// ui-kit
import { Button, Row } from '../../ui-kit' 
// svg
import Close from '../../icons/header/close.svg'

export default function Form({text}) {
    const formRef = useRef(null);
    const { register, control, errors, handleSubmit, reset } = useForm(); // watch, errors
    
    const [modalIsOpen, setIsOpen] = useState(false);
    let wTop = useRef(0);
    
    const onSubmit = data => {
        // fetch
        fetch('https://crm.zoho.eu/crm/WebToLeadForm', {
            method: 'post',
            body: new FormData(formRef.current)
        }).then(function(response) {
            if (response.status === 200) {
                console.log(window.scrollY, wTop);
                wTop.current = window.scrollY;
                console.log(wTop.current);
                document.documentElement.classList.add(s.fixed);
                document.documentElement.style.top = -wTop.current+"px";
                setIsOpen(true);
                reset();
            }
        })
    }
    
    return (
        <>
            <form name='WebToLeads212445000005185009' method='POST' ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <input ref={register} type='text' style={{ display: 'none' }} name='xnQsjsdp' value='0096430e3cbb3fb994f032a3bfd7b6d8ac29a3263c0e4f29add44c98070c5a3e'/>
                <input ref={register} type='hidden' name='zc_gad' id='zc_gad' value=''/>
                <input ref={register} type='text' style={{ display: 'none' }} name='xmIwtLD' value='b3c0ee9b77ab75d4987fe3150398a445e1e799bdd46936131a8da8183a3d70ef'/>
                <input ref={register} type='text'  style={{ display: 'none' }} name='actionType' value='TGVhZHM='/>
                <div className={s.formGroup}>
                    <div className={s.inputWrapper}>
                        <input className={s.input} type="text" id='Last_Name' name='Last Name' placeholder={`${text.name}*`} ref={register({ required: true })} />
                        {errors["Last Name"] && <span className={s.error}>{text.nameEmpty}</span>}
                    </div>
                    <div className={s.inputWrapper}>
                        <Controller
                            as={InputMask}
                            mask="+38 (999) 999 99 99"
                            id='Phone'
                            name="Phone"
                            className={s.input}
                            control={control}
                            placeholder={`${text.phone}*`}
                            defaultValue=""
                            rules={{ 
                                required: text.phoneEmpty,
                                pattern: {
                                    value: /\+38 \(\d{3}\) \d{3} \d{2} \d{2}$/i,
                                    message: text.phoneEmpty,
                                }
                            }}
                            onChange={() => {
                                console.log("From onBlur: ", this);
                            }}
                            type="tel"
                        />
                        {errors.Phone && <span className={s.error}>{errors.Phone.message}</span>}
                    </div>
                    <div className={s.inputWrapper} style={{visibility: "hidden"}}>
                        <input className={s.input} id='Email' name='Email' type="email" placeholder={text.email} ref={register} />
                    </div>
                </div>
                <div className={s.formGroup}>
                    <Row justify="justify-content-center">
                        <Button className={classNames(s.submit, "green")} type="submit">{text.consultation}</Button>
                    </Row>
                </div>
                <script type='text/javascript' id='VisitorTracking'>
                {`var $zoho= $zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode:"69001ac4ae5e566c6314efc825274c98bc3df2fcdd14ef21674da8969cc3c1610b5e354ee404cab7a41e04eb58edaf7d", values:{},ready:function(){$zoho.salesiq.floatbutton.visible("hide");}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);function trackVisitor(){try{if($zoho){var LDTuvidObj = document.forms["WebToLeads212445000005185009"]["LDTuvid"];if(LDTuvidObj){LDTuvidObj.value = $zoho.salesiq.visitor.uniqueid();}var firstnameObj = document.forms["WebToLeads212445000005185009"]["First Name"];if(firstnameObj){name = firstnameObj.value +" "+name;}$zoho.salesiq.visitor.name(name);var emailObj = document.forms["WebToLeads212445000005185009"]["Email"];if(emailObj){email = emailObj.value;$zoho.salesiq.visitor.email(email);}}} catch(e){}}`}
                </script>
            </form>
            <Popup modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} wTop={wTop}/>
        </>
    )
}

const Popup = ({modalIsOpen, setIsOpen, wTop}) => {
    function closeModal(){
        setIsOpen(false);
        document.documentElement.classList.remove(s.fixed);
        document.documentElement.style.top = 0+"px";
        window.scrollTo(0, wTop.current);
        wTop.current = 0;
    }

    const customStyles = {
        content : {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          border: '1px solid #9FD833',
          padding: '2rem'
        }
    };
    
    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <button onClick={closeModal} className={s.close}><Close/></button>
            <div className={s.content}>
                <h4 className={s.ptitle}>Заявка принята</h4>
                <p className={s.ptext}>Спасибо, мы свяжемся с вами в ближайшее время</p>
            </div>
        </Modal>
    )
}