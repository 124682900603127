import React, { useState, useEffect } from 'react'
import Glide from "@glidejs/glide"
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"
import classNames from 'classnames'
import { motion } from "framer-motion"
// styles
import s from './slider.module.sass'
// icons
import Left from '../../icons/slider/arrow-left-green.svg'
import Right from '../../icons/slider/arrow-right-green.svg'
import LeftSmall from '../../icons/slider/arrow-left-green-small.svg'
import RightSmall from '../../icons/slider/arrow-right-green-small.svg'
import { navigate } from 'gatsby'

const Slider = ({element = 'glide', options, children, activeClass = false, autoplay = 0, overflow='', arrowType = '', arrowsSize = 'large'}) => {
    const [slider] = useState(new Glide(`.${element}`, options))
    const [timer, setTimer] = useState(null);
    const [bullets, setBullets] = useState(null);
    const [arrows, setArrows] = useState(null);
    
    useEffect(() => {
        slider.on("mount.after", function() {
            if (slider._c.Html._t.querySelectorAll('.glide__slides [data-src]').length) {
                slider._c.Html._t.querySelectorAll('.glide__slides [data-src]').forEach(slide => {
                    slide.addEventListener('click', function() {
                        if (!this.closest('.glide--swipeable').classList.contains('glide--dragging')) {
                            navigate(this.dataset.src,{
                                state: {
                                    modal: true,
                                }
                            })
                        }
                    })
                })
            }

            if (options.arrows)
                setArrows(<Arrows arrowType={arrowType} arrowsSize={arrowsSize} slider={slider}/>)
            
            if (autoplay && document.documentElement.clientWidth > 768) {
                setTimer(<TimeLine autoplay={autoplay} slider={slider}/>);
            } else {
                setTimer(null);
            }
        
            if (options.bullets)
                setBullets(<Bullets slidesCount={slider._c.Html.slides.length} slider={slider}/>)
                
            for (const [key, value] of Object.entries(slider._o.breakpoints)) {
                if (value['bullets'] !== undefined) {
                    if (document.documentElement.clientWidth <= key) {
                        if (value['bullets']) {
                            setBullets(<Bullets slidesCount={slider._c.Html.slides.length} slider={slider}/>);
                        } else {
                            setBullets(null);
                        }
                    }
                }
                if (value['arrows'] !== undefined) {
                    if (document.documentElement.clientWidth <= key) {
                        if (value['arrows']) {
                            setArrows(<Arrows arrowType={arrowType} arrowsSize={arrowsSize}/>);
                        } else {
                            setArrows(null);
                        }
                    }
                }
            }

            slider._c.Html._t.style.height = slider._c.Html._t.scrollHeight+'px';
            if (options.autoHeight) {
                slider._c.Html._t.classList.add("auto-height");
                slider._c.Html._t.style.height = slider._c.Html.slides[slider.index].scrollHeight+100+'px';
            }
        });

        slider.on(['mount.after', 'run.after'], function() {
            if (activeClass) {
                slider._c.Html.slides.forEach(element => {
                    if (element.classList.contains("glide__slide--active")) {
                        element.classList.add(activeClass);
                    } else {
                        element.classList.remove(activeClass);
                    }
                });
            }

            // if (options.bullets) {
            document.querySelectorAll(`.${element} [data-bullet]`).forEach(function(bullet) {
                if (bullet.dataset.bullet - 0 === slider.index) {
                    bullet.classList.add(s.activeBullet);
                } else {
                    bullet.classList.remove(s.activeBullet);
                }
            })

            if (options.autoHeight) {
                slider._c.Html._t.style.height = slider._c.Html.slides[slider.index].scrollHeight+100+'px';
            }
            // }
        });
        
        slider.mount();

        window.addEventListener('resize', function() {
            slider._c.Html._t.style.height = '';
            slider._c.Html._t.style.height = slider._c.Html._t.scrollHeight+'px';
        })
        
        
        return () => slider.destroy()
    }, [])
    
    return (
        <div className={classNames(element, s.slider)}>
            <div className={classNames("glide__track", {
                [s.visible]: overflow.includes('visible')
            })} data-glide-el="track">
                <ul className="glide__slides">
                    {children.map((slide, index) => {
                        return React.cloneElement(slide, {
                                key: index,
                                className: `${slide.props.className} glide__slide`,
                            })
                        })
                    }
                </ul>
            </div>
            { arrows }
            { timer }
            { bullets }
        </div>
    )
}

const Arrows = ({arrowType, arrowsSize, slider}) => {
    let left, right;
    if ( arrowsSize === 'small' ) {
        left = <LeftSmall/>;
        right = <RightSmall/>;
    } else {
        left = <Left/>;
        right = <Right/>;
    }
    
    return (
        <div className={classNames('glide__arrows', {
            [s.static]: arrowType.includes('static')
        })} data-glide-el="controls">
            <button className={classNames("glide__arrow glide__arrow--left", s.arrow, s.arrowLeft, {
                [s.staticArrow]: arrowType.includes('static'),
                [s.smallArrow]: arrowsSize.includes('small')
            })} aria-label="prev" onClick={() => {slider.go("<")}}>{left}</button>
            <button className={classNames("glide__arrow glide__arrow--right", s.arrow, s.arrowRight, {
                [s.staticArrow]: arrowType.includes('static'),
                [s.smallArrow]: arrowsSize.includes('small')
            })} aria-label="next" onClick={() => {slider.go(">")}}>{right}</button>
        </div>
    )
}

const Bullets = ({slidesCount, slider}) => {
    function changeSlider(index) {
        slider.go(index);
    }
    
    let bullets = [];
    
    for (let i=0; i < slidesCount; i++) {
        bullets.push(<button 
                        type="button" 
                        key={i} 
                        className={ classNames(s.bullet, {
                            [s.activeBullet]: i === 0
                        }) } 
                        onClick={() => changeSlider(`=${i}`)}
                        data-bullet={i}
                    ></button>)
    }

    return (
        <div className={ s.bullets }>
            { bullets }
        </div>
    )
}

const TimeLine = ({autoplay, slider}) => {
    function onComplete(latest) {
        if (latest.scaleX === 1) {
            slider.go('>');
        }
    }
    return (
        <div className={s.timer}>
            {/* <div className={s.timerProgress}></div> */}
            <motion.div 
                className={s.timerProgress}
                initial={{scaleX: 0}}
                animate={{
                    scaleX: 1,
                    transition: {
                        type: "linear",
                        duration: autoplay/1000,
                        repeat: Infinity
                    }
                }}
                onUpdate={onComplete}
                exit={{scaleX: 1}}
                ></motion.div>
            <div className={s.timerTrack}></div>
        </div>
    )
}

export default Slider;