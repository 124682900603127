import React from "react"
import s from "./title.module.sass"
import classNames from "classnames"
import PropTypes from "prop-types"

export default function Title({children, className, type}) {
    if (type === 'page') {
        return <h1 className={classNames(s.title1, className)}>{children}</h1>
    }
    if (type === 'subtitle') {
        return <h3 className={classNames(s.subtitle, className, {
            [s.white]: className.includes('white'),
            [s.center]: className.includes('center'),
        })}>{children}</h3>
    }
    return <h2 className={classNames(s.title2, className)}>{children}</h2>
}

Title.propTypes = {
    type: PropTypes.string,
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
}


Title.defaultProps = {
    type: `section`,
    className: '',
}